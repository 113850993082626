import { Input, OnInit, Component } from '@angular/core';

@Component({
  template: `
    <div>{{ firstName }} {{ lastName }}</div>
  `
})
export class CustomerNameRenderComponent implements OnInit {
  firstName: string;
  lastName: string;

  @Input()
  value: any;

  ngOnInit() {
    if (this.value) {
      this.firstName = this.value.firstName;
      this.lastName = this.value.lastName;
    }
  }
}

@Component({
  template: `
    <div *ngIf="isRegularConsumer">Gewone klant</div>
    <div *ngIf="isBusiness">
      <b>Bedrijf</b><br />
      <span>BTW nr.: {{ company.vatNumber }}</span
      ><br />
      <span>Naam: {{ company.name }}</span>
    </div>
    <div *ngIf="isRetailer">
      <b>Bedrijf</b><br />
      <span>BTW nr.: {{ company.vatNumber }}</span
      ><br />
      <span>Naam: {{ company.name }}</span
      ><br />
      <small
        >* Dit is een doorverkoper en ziet dus de doorverkoopprijzen op de
        website</small
      >
    </div>
  `
})
export class CustomerTypeRenderComponent implements OnInit {
  isRegularConsumer: boolean;
  isBusiness: boolean;
  isRetailer: boolean;
  company: object; //vatNumber, name

  @Input()
  value: any;

  ngOnInit() {
    if (this.value) {
      this.isRegularConsumer = this.value.customerType === 'consumer';
      this.isBusiness = this.value.customerType === 'business';
      this.isRetailer = this.value.customerType === 'retailer';
      this.company = this.value.company;
    }
  }
}

@Component({
  template: `
    <div *ngIf="!mainAddress">Geen adres gekend</div>
    <div *ngIf="mainAddress">
      <span>{{ mainAddress.streetAndNr }}</span
      ><br />
      <span>{{ mainAddress.postalCodeAndCity }}</span
      ><br />
      <span>{{ mainAddress.country }}</span
      ><br />
      <div *ngIf="mainAddress.isCompanyAddress">
        <b><small>* Dit is het bedrijfsadres</small></b>
      </div>
      <small
        *ngIf="otherAddresses.length === 1"
        (click)="onToggleOtherAddresses()"
        style="cursor:pointer;"
        >1 ander addres</small
      >
      <small
        *ngIf="otherAddresses.length > 1"
        (click)="onToggleOtherAddresses()"
        style="cursor:pointer;"
        >{{ otherAddresses.length }} andere addressen</small
      >
      <ng-container *ngIf="showOtherAddresses">
        <div
          *ngFor="let address of otherAddresses"
          style="border-top:1px dashed #aaa; margin-left:10px;"
        >
          <small>{{ address.streetAndNr }}</small
          ><br />
          <small>{{ address.postalCodeAndCity }}</small
          ><br />
          <small>{{ address.country }}</small> <br />
          <div *ngIf="address.isCompanyAddress">
            <b><small>* Dit is het bedrijfsadres</small></b>
          </div>
        </div>
      </ng-container>
    </div>
  `
})
export class CustomerAddressesRenderComponent implements OnInit {
  mainAddress: any;
  otherAddresses: any[];
  showOtherAddresses: boolean = false;

  @Input()
  value: any;

  ngOnInit() {
    if (this.value && this.value.length) {
      this.mainAddress =
        this.value.find(address => address.isDefault) || this.value[0];
      this.otherAddresses = this.value.filter(
        address =>
          address.streetAndNr !== this.mainAddress.streetAndNr ||
          address.postalCodeAndCity !== this.mainAddress.postalCodeAndCity ||
          address.country !== this.mainAddress.country
      );
    }
  }

  onToggleOtherAddresses(): void {
    this.showOtherAddresses = !this.showOtherAddresses;
  }
}

@Component({
  template: `
    <a routerLink="/pages/order/customer/{{ customerId }}">
      <span *ngIf="orders.length !== 1">{{ orders.length }} bestellingen</span>
      <span *ngIf="orders.length === 1">1 bestelling</span>
    </a>
  `
})
export class CustomerOrdersRenderComponent implements OnInit {
  customerId: string;
  orders: any[];

  @Input()
  value: any;
  @Input()
  rowData: any;

  ngOnInit() {
    if (this.value) {
      this.orders = this.value || [];
    } else {
      this.orders = [];
    }
    this.customerId = this.rowData.id;
  }
}

@Component({
  template: `
    <div>Workshop inschrijvingen... (TODO)</div>
  `
})
export class CustomerWorkshopRegistrationsRenderComponent implements OnInit {
  @Input()
  value: any;

  ngOnInit() {
    if (this.value) {
      //
    }
  }
}
